const Paths = {
  Home: 'Home',
  Contact: 'Contact',
  HotDeals: 'HotDeals',
  HowItWorks: 'HowItWorks',
  ForBusiness: 'ForBusiness',
  Cluster: 'Cluster',
  AboutUs: 'AboutUs',
  OurMission: 'OurMission',
  TermsAndConditionsDE: 'TermsAndConditionsDE',
  TermsAndConditionsNL: 'TermsAndConditionsNL',
  PrivacyPolicyDE: 'PrivacyPolicyDE',
  PrivacyPolicyNL: 'PrivacyPolicyNL',
  CompanyDetails: 'CompanyDetails',
  Faq: 'Faq',
  ProductsOverview: 'ProductsOverview',
  PDP: 'PDP',
  PCP: 'PCP',
  Landing: 'Landing',
  ForgotPassword: 'ForgotPassword',
  ResetPassword: 'ResetPassword',
  Login: 'Login',
  MyBluemovement: 'MyBluemovement',
  UserPanelSubscriptions: 'UserPanelSubscriptions',
  UserPanelSubscriptionDetails: 'UserPanelSubscriptionDetails',
  UserPanelSubscriptionRepair: 'UserPanelSubscriptionRepair',
  UserPanelSubscriptionCancel: 'UserPanelSubscriptionCancel',
  UserPanelTakeover: 'UserPanelTakeover',
  UserPanelTakeoverOverview: 'UserPanelTakeoverOverview',
  UserPanelTakeoverReject: 'UserPanelTakeoverReject',
  UserPanelTakeoverUnpaidInvoices: 'UserPanelTakeoverUnpaidInvoices',
  UserPanelInvoices: 'UserPanelInvoices',
  UserPanelReferAFriend: 'UserPanelReferAFriend',
  UserPanelHelp: 'UserPanelHelp',
  UserPanelPersonalDetails: 'UserPanelPersonalDetails',
  UserPanelPreferences: 'UserPanelPreferences',
  UserPanelChangeMarketingOptin: 'UserPanelChangeMarketingOptin',
  UserPanelMovingForm: 'UserPanelMovingForm',
  UserPanelChangeAddress: 'UserPanelChangeAddress',
  UserPanelChangePassword: 'UserPanelChangePassword',
  UserPanelChangePaymentMethod: 'UserPanelChangePaymentMethod',
  UserPanelChangeSubscription: 'UserPanelChangeSubscription',
  UserPanelRenewalSubscription: 'UserPanelRenewalSubscription',
  UserPanelSwitchContractOverview: 'UserPanelSwitchContractOverview',
  Cookies: 'Cookies',
  NewMandate: 'NewMandate',
  MyBMSubscriptions: 'MyBMSubscriptions',
  CountrySelector: 'CountrySelector',
  NotFound: 'NotFound',
  DevComponents: 'DevComponents',
  DevButtons: 'DevButtons',
  CreatePaymentLink: 'CreatePaymentLink',
  ConfirmationPaymentLink: 'ConfirmationPaymentLink',
  Sitemap: 'Sitemap',
  DevLogin: 'DevLogin',
  Info: 'Info',
  // TO DO: REMOVE AFTER ALL OLD TAKEOVERS ARE EXPIRED
  UserPanelTakeoverReceiverOld: 'UserPanelTakeoverReceiverOld',
  AcceptanceOfCommercialApproach: 'AcceptanceOfCommercialApproach',
  IdConfirmation: 'IdConfirmation',
  Blog: 'Blog',
  BlogPostDE: 'BlogPostDE',
  BlogPostNL: 'BlogPostNL',
  CheckoutOrderPreview: 'CheckoutOrderPreview',
  CheckoutCustomerDetails: 'CheckoutCustomerDetails',
  CheckoutDelivery: 'CheckoutDelivery',
  CheckoutPayment: 'CheckoutPayment',
  CheckoutPaymentStatus: 'CheckoutPaymentStatus',
  CheckoutOrderConfirmation: 'CheckoutOrderConfirmation',
  CheckoutOrderNotAccepted: 'CheckoutOrderNotAccepted',
  CheckoutCreditCheck: 'CheckoutCreditCheck',
  CheckoutFinanceCustomerDetails: 'CheckoutFinanceCustomerDetails',
  PaymentStatus: 'PaymentStatus',
  Cart: 'Cart',
};

module.exports = {
  Paths,
};
