const { devPaths } = require('./enabled-custom-paths');
const { enabledDevPaths } = require('./enabled-dev-paths');
const { enabledProductionPaths } = require('./enabled-production-paths');
const { enabledQaPaths } = require('./enabled-qa.paths');
const { Paths } = require('./paths');
const config = require('../../services/config/server');
const { Locales } = require('../../utils/paths/locale');

const PagesWithNationalSlug = {
  [Paths.Home]: {
    storyblokSlug: {
      nl: 'nl/pages/general/home',
      de: 'de/pages/general/home',
    },
    paths: { [Locales.nl.nl]: '/', [Locales.nl.en]: '/', [Locales.de.de]: '/' },
  },
  [Paths.HotDeals]: {
    storyblokSlug: {
      nl: 'nl/pages/general/hot-deals',
      de: 'de/pages/general/hot-deals',
    },
    paths: { [Locales.nl.nl]: '/aanbiedingen', [Locales.nl.en]: '/hot-deals', [Locales.de.de]: '/hot-deals' },
  },
  [Paths.ProductsOverview]: {
    storyblokSlug: {
      nl: 'nl/pages/general/products-overview',
      de: 'de/pages/general/products-overview',
    },
    paths: {
      [Locales.nl.nl]: '/abonnementen',
      [Locales.nl.en]: '/plans',
      [Locales.de.de]: '/abos',
    },
    sitemapPriority: 0.9,
  },
  [Paths.HowItWorks]: {
    storyblokSlug: {
      nl: 'nl/pages/general/how-it-works',
      de: 'de/pages/general/how-it-works',
    },
    paths: {
      [Locales.nl.nl]: '/hoe-werkt-het',
      [Locales.nl.en]: '/how-it-works',
      [Locales.de.de]: '/so-funktionierts',
    },
  },
  [Paths.ForBusiness]: {
    storyblokSlug: {
      nl: 'nl/pages/general/for-business',
      de: 'de/pages/general/for-business',
    },
    paths: { [Locales.nl.nl]: '/zakelijk', [Locales.nl.en]: '/for-business', [Locales.de.de]: '/fuer-unternehmen' },
  },
  [Paths.Cluster]: {
    storyblokSlug: {
      nl: 'nl/pages/product-clusters/:cluster',
      de: 'de/pages/product-clusters/:cluster',
    },
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/:cluster',
      [Locales.nl.en]: '/:cluster',
      [Locales.de.de]: '/:cluster',
    },
  },
};

const CommonPaths = {
  [Paths.NotFound]: {
    storyblokSlug: 'pages-new/error/not-found',
    paths: { [Locales.nl.nl]: '/404', [Locales.nl.en]: '/404', [Locales.de.de]: '/404' },
  },
  [Paths.Contact]: {
    storyblokSlug: 'pages-new/general/contact',
    paths: {
      [Locales.nl.nl]: '/contact',
      [Locales.nl.en]: '/contact',
      [Locales.de.de]: '/kontakt',
    },
    sitemapPriority: 0.9,
  },
  [Paths.CountrySelector]: {
    storyblokSlug: 'pages-new/general/country-selector',
    paths: {}, // not country specific
  },
  [Paths.DevLogin]: {
    storyblokSlug: 'pages-new/auth/dev-login',
    paths: {}, // not country specific
  },
  [Paths.AboutUs]: {
    storyblokSlug: 'pages-new/general/about-us',
    paths: {
      [Locales.nl.nl]: '/over-ons',
      [Locales.nl.en]: '/about-us',
      [Locales.de.de]: '/ueber-uns',
    },
    sitemapPriority: 0.9,
  },
  [Paths.OurMission]: {
    storyblokSlug: 'pages-new/general/our-mission',
    paths: {
      [Locales.nl.nl]: '/onze-missie',
      [Locales.nl.en]: '/our-mission',
      [Locales.de.de]: '/unsere-mission',
    },
  },
  [Paths.TermsAndConditionsNL]: {
    storyblokSlug: 'pages-new/legal/terms-and-conditions-nl',
    paths: {
      [Locales.nl.nl]: '/algemene-voorwaarden',
      [Locales.nl.en]: '/terms-and-conditions',
      [Locales.de.de]: '/allgmeine-geschaeftsbedingungen',
    },
    sitemapPriority: 0.6,
  },
  [Paths.TermsAndConditionsDE]: {
    storyblokSlug: 'pages-new/legal/terms-and-conditions-de',
    paths: {
      [Locales.nl.nl]: '/algemene-voorwaarden',
      [Locales.nl.en]: '/terms-and-conditions',
      [Locales.de.de]: '/allgmeine-geschaeftsbedingungen',
    },
    sitemapPriority: 0.6,
  },
  [Paths.PrivacyPolicyNL]: {
    storyblokSlug: 'pages-new/legal/privacy-policy-nl',
    paths: {
      [Locales.nl.nl]: '/informatie-over-gegevensbescherming',
      [Locales.nl.en]: '/privacy-policy',
      // [Locales.de.de]: '/datenschutz',
    },
    sitemapPriority: 0.6,
  },
  [Paths.PrivacyPolicyDE]: {
    storyblokSlug: 'pages-new/legal/privacy-policy-de',
    paths: {
      // [Locales.nl.nl]: '/informatie-over-gegevensbescherming',
      // [Locales.nl.en]: '/privacy-policy',
      [Locales.de.de]: '/datenschutz',
    },
    sitemapPriority: 0.6,
  },
  [Paths.CompanyDetails]: {
    storyblokSlug: 'pages-new/legal/company-details',
    paths: {
      [Locales.nl.nl]: '/colofon',
      [Locales.nl.en]: '/company-details',
      [Locales.de.de]: '/company-details',
    },
    sitemapPriority: 0.6,
  },
  [Paths.Cookies]: {
    storyblokSlug: 'pages-new/legal/cookies',
    paths: {
      [Locales.nl.nl]: '/cookie-declaration',
      [Locales.nl.en]: '/cookie-declaration',
      [Locales.de.de]: '/cookie-richtlinien',
    },
    sitemapPriority: 0.6,
  },
  [Paths.Faq]: {
    storyblokSlug: 'pages-new/general/faq',
    paths: {
      [Locales.nl.nl]: '/veelgestelde-vragen',
      [Locales.nl.en]: '/frequently-asked-questions',
      [Locales.de.de]: '/haeufige-fragen',
    },
    sitemapPriority: 0.9,
  },
  [Paths.ForgotPassword]: {
    storyblokSlug: 'pages-new/auth/forgot-password',
    paths: {
      [Locales.nl.nl]: '/forgot-password',
      [Locales.nl.en]: '/forgot-password',
      [Locales.de.de]: '/forgot-password',
    },
  },
  [Paths.ResetPassword]: {
    storyblokSlug: 'pages-new/auth/reset-password',
    paths: {
      [Locales.nl.nl]: '/auth/new-password',
      [Locales.nl.en]: '/auth/new-password',
      [Locales.de.de]: '/auth/new-password',
    },
  },
  [Paths.Login]: {
    storyblokSlug: 'pages-new/auth/login',
    paths: {
      [Locales.nl.nl]: '/auth/login',
      [Locales.nl.en]: '/auth/login',
      [Locales.de.de]: '/auth/login',
    },
  },
  [Paths.NewMandate]: {
    storyblokSlug: 'pages-new/payment-unpaid-invoices/new-mandate',
    paths: {
      [Locales.nl.nl]: '/auth/new-mandate',
      [Locales.nl.en]: '/auth/new-mandate',
      [Locales.de.de]: '/auth/new-mandate',
    },
  },
  [Paths.DevComponents]: {
    storyblokSlug: 'pages-new/dev/dev-components',
    paths: {
      [Locales.nl.nl]: '/dev-components',
      [Locales.nl.en]: '/dev-components',
      [Locales.de.de]: '/dev-components',
    },
  },
  [Paths.DevButtons]: {
    storyblokSlug: 'pages-new/dev/dev-buttons',
    paths: {
      [Locales.nl.nl]: '/dev-buttons',
      [Locales.nl.en]: '/dev-buttons',
      [Locales.de.de]: '/dev-buttons',
    },
  },
  [Paths.CreatePaymentLink]: {
    storyblokSlug: 'pages-new/payment-unpaid-invoices/payment-link-info',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/payment/invoice/:invoiceId/create/:paymentLinkId',
      [Locales.nl.en]: '/payment/invoice/:invoiceId/create/:paymentLinkId',
      [Locales.de.de]: '/payment/invoice/:invoiceId/create/:paymentLinkId',
    },
  },
  [Paths.ConfirmationPaymentLink]: {
    storyblokSlug: 'pages-new/payment-unpaid-invoices/payment-confirmation',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/payment/invoice/:invoiceId/confirmation/:paymentBMId',
      [Locales.nl.en]: '/payment/invoice/:invoiceId/confirmation/:paymentBMId',
      [Locales.de.de]: '/payment/invoice/:invoiceId/confirmation/:paymentBMId',
    },
  },
  [Paths.Info]: {
    storyblokSlug: 'pages-new/general/info',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/info/:type',
      [Locales.nl.en]: '/info/:type',
      [Locales.de.de]: '/info/:type',
    },
  },
  [Paths.Blog]: {
    storyblokSlug: 'pages-new/blog',
    paths: {
      [Locales.nl.nl]: '/blog',
      [Locales.nl.en]: '/blog',
      [Locales.de.de]: '/blog',
    },
  },
  [Paths.BlogPostDE]: {
    storyblokSlug: 'pages-new/blog/posts/de/:slug',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/blog',
      [Locales.nl.en]: '/blog',
      [Locales.de.de]: '/blog/:slug',
    },
  },
  [Paths.BlogPostNL]: {
    storyblokSlug: 'pages-new/blog/posts/nl/:slug',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/blog/:slug',
      [Locales.nl.en]: '/blog/:slug',
      [Locales.de.de]: '/blog',
    },
  },
  [Paths.Landing]: {
    storyblokSlug: 'pages-new/landing-:country/:slug',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/:slug',
      [Locales.nl.en]: '/:slug',
      [Locales.de.de]: '/:slug',
    },
  },
  [Paths.PaymentStatus]: {
    storyblokSlug: 'pages-new/general/payment-status',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/payment-status/:transactionId',
      [Locales.nl.en]: '/payment-status/:transactionId',
      [Locales.de.de]: '/payment-status/:transactionId',
    },
  },
};

const CheckoutPaths = {
  [Paths.Cart]: {
    storyblokSlug: 'pages-new/checkout/cart',
    paths: {
      [Locales.nl.nl]: '/cart',
      [Locales.nl.en]: '/cart',
      [Locales.de.de]: '/cart',
    },
  },
  [Paths.CheckoutOrderPreview]: {
    storyblokSlug: 'pages-new/checkout/order-preview',
    paths: {
      [Locales.nl.nl]: '/checkout/order-preview',
      [Locales.nl.en]: '/checkout/order-preview',
      [Locales.de.de]: '/checkout/order-preview',
    },
  },
  [Paths.CheckoutCustomerDetails]: {
    storyblokSlug: 'pages-new/checkout/customer-details',
    paths: {
      [Locales.nl.nl]: '/checkout/customer-details',
      [Locales.nl.en]: '/checkout/customer-details',
      [Locales.de.de]: '/checkout/customer-details',
    },
  },
  [Paths.CheckoutDelivery]: {
    storyblokSlug: 'pages-new/checkout/delivery',
    paths: {
      [Locales.nl.nl]: '/checkout/delivery',
      [Locales.nl.en]: '/checkout/delivery',
      [Locales.de.de]: '/checkout/delivery',
    },
  },
  [Paths.CheckoutPayment]: {
    storyblokSlug: 'pages-new/checkout/payment',
    paths: {
      [Locales.nl.nl]: '/checkout/payment',
      [Locales.nl.en]: '/checkout/payment',
      [Locales.de.de]: '/checkout/payment',
    },
  },
  [Paths.CheckoutPaymentStatus]: {
    storyblokSlug: 'pages-new/checkout/payment-status',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/checkout/payment-status/:transactionId',
      [Locales.nl.en]: '/checkout/payment-status/:transactionId',
      [Locales.de.de]: '/checkout/payment-status/:transactionId',
    },
  },
  [Paths.CheckoutOrderConfirmation]: {
    storyblokSlug: 'pages-new/checkout/order-confirmation',
    paths: {
      [Locales.nl.nl]: '/checkout/order-confirmation',
      [Locales.nl.en]: '/checkout/order-confirmation',
      [Locales.de.de]: '/checkout/order-confirmation',
    },
  },
  [Paths.IdConfirmation]: {
    storyblokSlug: 'pages-new/checkout/id-confirmation',
    paths: {
      [Locales.nl.nl]: '/checkout/id-confirmation',
      [Locales.nl.en]: '/checkout/id-confirmation',
      [Locales.de.de]: '/checkout/id-confirmation',
    },
  },
  [Paths.CheckoutOrderNotAccepted]: {
    storyblokSlug: 'pages-new/checkout/order-not-accepted',
    paths: {
      [Locales.nl.nl]: '/checkout/order-not-accepted',
      [Locales.nl.en]: '/checkout/order-not-accepted',
      [Locales.de.de]: '/checkout/order-not-accepted',
    },
  },
  [Paths.CheckoutCreditCheck]: {
    storyblokSlug: 'pages-new/checkout/credit-check',
    paths: {
      [Locales.nl.nl]: '/checkout/credit-check',
      [Locales.nl.en]: '/checkout/credit-check',
      [Locales.de.de]: '/checkout/credit-check',
    },
  },
};

const FinanceCheckoutPaths = {
  [Paths.CheckoutFinanceCustomerDetails]: {
    storyblokSlug: 'pages-new/checkout-finance/customer-details',
    paths: {
      [Locales.de.de]: '/checkout-finance/customer-details',
    },
  },
};

const ProductsPaths = {
  [Paths.PDP]: {
    storyblokSlug: 'pages-new/pdp-:country/:category/:vib',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/abonnementen/:category/:vib',
      [Locales.nl.en]: '/plans/:category/:vib',
      [Locales.de.de]: '/abos/:category/:vib',
    },
  },
  [Paths.PCP]: {
    storyblokSlug: 'pages-new/pcp-:country/:category',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/abonnementen/:category',
      [Locales.nl.en]: '/plans/:category',
      [Locales.de.de]: '/abos/:category',
    },
  },
};

const UserPanelPaths = {
  [Paths.UserPanelSubscriptions]: {
    storyblokSlug: 'pages-new/user-panel/subscriptions',
    paths: {
      [Locales.nl.nl]: '/user/subscriptions',
      [Locales.nl.en]: '/user/subscriptions',
      [Locales.de.de]: '/user/subscriptions',
    },
  },
  [Paths.UserPanelSubscriptionDetails]: {
    storyblokSlug: 'pages-new/user-panel/subscription-details',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/subscriptions/:id',
      [Locales.nl.en]: '/user/subscriptions/:id',
      [Locales.de.de]: '/user/subscriptions/:id',
    },
  },
  [Paths.UserPanelSubscriptionCancel]: {
    storyblokSlug: 'pages-new/user-panel/cancel-subscription',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/subscriptions/:id/cancel',
      [Locales.nl.en]: '/user/subscriptions/:id/cancel',
      [Locales.de.de]: '/user/subscriptions/:id/cancel',
    },
  },
  [Paths.UserPanelSubscriptionRepair]: {
    storyblokSlug: 'pages-new/user-panel/repair-subscription',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/subscriptions/:id/repair',
      [Locales.nl.en]: '/user/subscriptions/:id/repair',
      [Locales.de.de]: '/user/subscriptions/:id/repair',
    },
  },
  [Paths.UserPanelInvoices]: {
    storyblokSlug: 'pages-new/user-panel/invoices',
    paths: {
      [Locales.nl.nl]: '/user/invoices',
      [Locales.nl.en]: '/user/invoices',
      [Locales.de.de]: '/user/invoices',
    },
  },
  [Paths.UserPanelPersonalDetails]: {
    storyblokSlug: 'pages-new/user-panel/personal-details',
    paths: {
      [Locales.nl.nl]: '/user/personal-details',
      [Locales.nl.en]: '/user/personal-details',
      [Locales.de.de]: '/user/personal-details',
    },
  },
  [Paths.MyBMSubscriptions]: {
    storyblokSlug: '',
    paths: {
      [Locales.nl.nl]: '/user/subscriptions',
      [Locales.nl.en]: '/user/subscriptions',
      [Locales.de.de]: '/user/subscriptions',
    },
  },
  [Paths.UserPanelHelp]: {
    storyblokSlug: 'pages-new/user-panel/help',
    paths: {
      [Locales.nl.nl]: '/user/help',
      [Locales.nl.en]: '/user/help',
      [Locales.de.de]: '/user/help',
    },
  },
  [Paths.UserPanelReferAFriend]: {
    storyblokSlug: 'pages-new/user-panel/refer-a-friend',
    paths: {
      [Locales.nl.nl]: '/user/refer-a-friend',
      [Locales.nl.en]: '/user/refer-a-friend',
      [Locales.de.de]: '/user/refer-a-friend',
    },
  },
  [Paths.UserPanelMovingForm]: {
    storyblokSlug: 'pages-new/user-panel/moving-form',
    paths: {
      [Locales.nl.nl]: '/user/verhuisplanner',
      [Locales.nl.en]: '/user/moving-form',
    },
  },
  [Paths.UserPanelTakeover]: {
    storyblokSlug: 'pages-new/user-panel/takeover-pages/takeover',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/takeover/:type',
      [Locales.nl.en]: '/user/takeover/:type',
      [Locales.de.de]: '/user/takeover/:type',
    },
  },
  [Paths.UserPanelTakeoverOverview]: {
    storyblokSlug: 'pages-new/user-panel/takeover-pages/overview',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/takeover/overview',
      [Locales.nl.en]: '/user/takeover/overview',
      [Locales.de.de]: '/user/takeover/overview',
    },
  },
  [Paths.UserPanelTakeoverReject]: {
    storyblokSlug: 'pages-new/user-panel/takeover-pages/reject',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/takeover/receiver-reject',
      [Locales.nl.en]: '/takeover/receiver-reject',
      [Locales.de.de]: '/takeover/receiver-reject',
    },
  },
  [Paths.UserPanelTakeoverUnpaidInvoices]: {
    storyblokSlug: 'pages-new/user-panel/takeover-pages/unpaid-invoices',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/takeover/unpaid-invoices',
      [Locales.nl.en]: '/user/takeover/unpaid-invoices',
      [Locales.de.de]: '/user/takeover/unpaid-invoices',
    },
  },
  // TO DO: REMOVE AFTER ALL OLD TAKEOVERS ARE EXPIRED
  [Paths.UserPanelTakeoverReceiverOld]: {
    storyblokSlug: '',
    paths: {
      [Locales.nl.nl]: '/takeover/receiver1',
      [Locales.nl.en]: '/takeover/receiver1',
      [Locales.de.de]: '/takeover/receiver1',
    },
  },
  [Paths.UserPanelChangeMarketingOptin]: {
    storyblokSlug: 'pages-new/user-panel/change-marketing-optin',
    paths: {
      [Locales.nl.nl]: '/user/change-marketing-optin',
      [Locales.nl.en]: '/user/change-marketing-optin',
      [Locales.de.de]: '/user/change-marketing-optin',
    },
  },
  [Paths.UserPanelChangePassword]: {
    storyblokSlug: 'pages-new/user-panel/change-password',
    paths: {
      [Locales.nl.nl]: '/user/change-password',
      [Locales.nl.en]: '/user/change-password',
      [Locales.de.de]: '/user/change-password',
    },
  },
  [Paths.UserPanelRepairRequest]: {
    storyblokSlug: 'pages-new/user-panel/repair-request',
    paths: {
      [Locales.nl.nl]: '/user/repair-request',
      [Locales.nl.en]: '/user/repair-request',
      [Locales.de.de]: '/user/repair-request',
    },
  },
  [Paths.UserPanelChangePaymentMethod]: {
    storyblokSlug: 'pages-new/user-panel/change-payment-method',
    paths: {
      [Locales.nl.nl]: '/user/change-payment-method',
      [Locales.nl.en]: '/user/change-payment-method',
      [Locales.de.de]: '/user/change-payment-method',
    },
  },
  [Paths.UserPanelChangeAddress]: {
    storyblokSlug: 'pages-new/user-panel/change-address',
    paths: {
      [Locales.nl.nl]: '/user/change-address',
      [Locales.nl.en]: '/user/change-address',
      [Locales.de.de]: '/user/change-address',
    },
  },
  [Paths.UserPanelPreferences]: {
    storyblokSlug: 'pages-new/user-panel/preferences',
    paths: {
      [Locales.nl.nl]: '/user/preferences',
      [Locales.nl.en]: '/user/preferences',
      [Locales.de.de]: '/user/preferences',
    },
  },
  [Paths.MyBluemovement]: {
    storyblokSlug: 'pages-new/user-panel/my-bluemovement',
    paths: {
      [Locales.nl.nl]: '/user/mybluemovement',
      [Locales.nl.en]: '/user/mybluemovement',
      [Locales.de.de]: '/user/mybluemovement',
    },
  },
  [Paths.Sitemap]: {
    storyblokSlug: '',
    paths: {
      [Locales.nl.nl]: '/sitemap',
      [Locales.nl.en]: '/sitemap',
      [Locales.de.de]: '/sitemap',
    },
  },
  [Paths.AcceptanceOfCommercialApproach]: {
    storyblokSlug: 'pages-new/checkout/marketing-double-opt-in',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/contact/accept',
      [Locales.nl.en]: '/contact/accept',
      [Locales.de.de]: '/contact/accept',
    },
  },
  [Paths.UserPanelRenewalSubscription]: {
    storyblokSlug: 'pages-new/user-panel/switch/renewal-subscription',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/subscriptions/:id/renewal',
      [Locales.nl.en]: '/user/subscriptions/:id/renewal',
      [Locales.de.de]: '/user/subscriptions/:id/renewal',
    },
  },
  [Paths.UserPanelChangeSubscription]: {
    storyblokSlug: 'pages-new/user-panel/switch/change-subscription',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/subscriptions/:id/change',
      [Locales.nl.en]: '/user/subscriptions/:id/change',
      [Locales.de.de]: '/user/subscriptions/:id/change',
    },
  },
  [Paths.UserPanelSwitchContractOverview]: {
    storyblokSlug: 'pages-new/user-panel/switch/overview',
    isDynamic: true,
    paths: {
      [Locales.nl.nl]: '/user/subscriptions/:id/contract-switch-overview',
      [Locales.nl.en]: '/user/subscriptions/:id/contract-switch-overview',
      [Locales.de.de]: '/user/subscriptions/:id/contract-switch-overview',
    },
  },
};

const PathsConfig = {
  ...PagesWithNationalSlug,
  ...ProductsPaths,
  ...UserPanelPaths,
  ...CheckoutPaths,
  ...FinanceCheckoutPaths,
  ...CommonPaths,
};

const EnabledPathsPerEnv = {
  'aws-prod': enabledProductionPaths,
  'aws-qa': enabledQaPaths,
  'aws-dev': enabledDevPaths,
  // Local devs
  'dawid-dev': [...devPaths],
  'chris-dev': [...devPaths],
  'jakub-dev': [...devPaths],
  'klaudia-dev': [...devPaths],
  'tomek-dev': [...devPaths],
  'karol-dev': [...devPaths],
};

const EnabledPaths =
  EnabledPathsPerEnv[config.environmentName] || (config.envType !== 'production' && Object.values(Paths)) || [];

module.exports = {
  PathsConfig,
  EnabledPaths,
  PagesWithNationalSlug,
};
